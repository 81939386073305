import { ActionIcon, Anchor, Title, Tooltip } from '@mantine/core';
import { IconClipboardText, IconGripVertical } from '@tabler/icons-react';
import { ColumnDef } from '@tanstack/react-table';
import { DataTable } from 'components/Table';
import { Routes } from 'enums';
import React from 'react';
import { Link } from 'react-router-dom';
import { EOI } from '../types/eoi';
import { PrioritisedEOIAction } from './prioritised-eoi-action';

interface EOIListProps {
  eois: Array<EOI>;
  heading?: string;
  type: 'prioritised' | 'unprioritised' | 'archived';
}

const columns: ColumnDef<EOI>[] = [
  {
    id: 'handle',
    size: 32,
    cell: () => <IconGripVertical />,
    enableHiding: false
  },
  {
    size: 32,
    header: 'Priority',
    accessorKey: 'priority',
    accessorFn: row =>
      row.priority !== null ? row.priority + 1 : row.priority,
    enableHiding: false
  },
  {
    header: 'Participant Name',
    accessorFn: row => row.name || row.email,
    enableHiding: false
  },
  {
    header: 'Email',
    accessorKey: 'email'
  },
  {
    header: 'LHD',
    accessorKey: 'lhd',
    enableHiding: false
  },
  {
    header: 'Discipline',
    accessorKey: 'discipline',
    enableHiding: false
  },
  {
    header: 'Work Setting',
    accessorKey: 'work_setting',
    enableHiding: false
  },
  {
    header: 'Service Name',
    accessorKey: 'service_name',
    enableHiding: true
  },
  {
    header: 'Years of Clinical Experience',
    accessorKey: 'clinical_experience',
    enableHiding: true
  },
  {
    header: 'Years of ED Experience',
    accessorKey: 'ed_experience',
    enableHiding: true
  },
  {
    header: 'Location',
    accessorKey: 'location',
    enableHiding: true
  },
  {
    id: 'action',
    cell: ({ row }) => {
      return (
        <PrioritisedEOIAction
          enrolmentId={row.original.enrolment_id}
          userId={row.original.user_id}
        />
      );
    },
    enableHiding: false
  }
];

const columnVisibility = {
  'email': false,
  'service_name': false,
  'clinical_experience': false,
  'ed_experience': false,
  'location': false
};

export const EOIList = ({ eois, heading, type }: EOIListProps) => {
  return (
    <div>
      {heading && <Title order={1}>{heading}</Title>}
      <DataTable
        id={type}
        columns={columns}
        data={eois}
        extraRowData={{ type }}
        getRowId={row => row.enrolment_id}
        visibility={columnVisibility}
        droppable
        draggable
      />
    </div>
  );
};
