import React from 'react';
import { connect } from 'react-redux';
import { CourseIndex, RootState, UserBasic } from 'types/state';
import { AdminHeader } from './Admin';
import { GuestHeader } from './Guest';
import { LearnerHeader } from './Learner';
import { CourseHeader } from './Course';
import { UserRoles } from 'enums/user-roles';
import { logout as logoutRoutine } from 'store/user/routines';
import { useLocation, useParams } from 'react-router-dom';
import { Routes } from 'enums';
import { env } from 'env';

type Props = {
  isAuthenticated: boolean;
  role: UserRoles;
  email: string;
  basic: UserBasic;
  logout: () => void;
  courses: CourseIndex;
};

const HeaderComponent: React.FC<Props> = ({ isAuthenticated, role, email, basic, logout, courses }) => {
  const { pathname } = useLocation();
  const { id } = useParams();

  const staging = env.appEnv === 'development' || env.appEnv === 'staging';

  if (pathname.startsWith(Routes.courseEnrolment) && courses[id]) return <CourseHeader staging={staging} course={courses[id]} />;

  if (!isAuthenticated) return <GuestHeader staging={staging} />;

  if (role === UserRoles.LEARNER) return <LearnerHeader email={email} basic={basic} logout={logout} staging={staging} />;

  return <AdminHeader email={email} logout={logout} staging={staging} />;
};

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: state.user.isAuthenticated,
  role: state.user.role,
  email: state.user.email,
  basic: state.user.basic,
  courses: state.course.courses
});

const mapDispatchToProps = {
  logout: logoutRoutine
};

export const Header = connect(mapStateToProps, mapDispatchToProps)(HeaderComponent);
