import { Container, Tabs } from '@mantine/core';
import React from 'react';
import { DataExport } from './data-export';
import { SurveyExportPanel } from './survey-export';

export const DataExportPage = () => {
  return (
    <Container size="lg" my="lg">
      <Tabs w="100%" defaultValue="data">
        <Tabs.List grow>
          <Tabs.Tab value="data">Data</Tabs.Tab>
          <Tabs.Tab value="survey">Survey</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="data" my="xl">
          <DataExport />
        </Tabs.Panel>

        <Tabs.Panel value="survey" my="xl">
          <SurveyExportPanel />
        </Tabs.Panel>
      </Tabs>
    </Container>
  );
};
