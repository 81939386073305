import React from 'react';
import { ColumnDef, VisibilityState } from '@tanstack/react-table';
import { DataTable } from 'components/Table';
import {
  Discipline,
  EmploymentType,
  ExperienceWithEatingDisorder,
  NSWHealthLHD
} from 'enums/user-info';
import { Anchor, Group, UnstyledButton } from '@mantine/core';
import { Link } from 'react-router-dom';
import { Routes } from 'enums/routing/routes';
import { SortableHeader } from 'components/Table/SortableHeader';

type Preference = {
  user_id: string;
  name?: string;
  email: string;
  lhd: NSWHealthLHD[keyof NSWHealthLHD];
  discipline: Discipline[keyof Discipline];
  work_setting: EmploymentType[keyof EmploymentType];
  service_name: string;
  clinical_experience: ExperienceWithEatingDisorder[keyof ExperienceWithEatingDisorder];
  ed_experience: ExperienceWithEatingDisorder[keyof ExperienceWithEatingDisorder];
  location: string;
};

interface AdminPreferenceTableProps {
  preferences: Array<Preference>;
}

const columns: ColumnDef<Preference>[] = [
  {
    id: 'count',
    size: 200,
    header: ({ column }) => {
      return (
        <SortableHeader
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          order={column.getIsSorted()}
        >
          Count
        </SortableHeader>
      );
    },
    accessorFn: (_, index) => index + 1,
    enableHiding: false
  },
  {
    id: 'name',
    header: ({ column }) => {
      return (
        <SortableHeader
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          order={column.getIsSorted()}
        >
          Name
        </SortableHeader>
      );
    },
    accessorFn: row => row.name || row.email,
    enableHiding: false
  },
  {
    header: 'Email',
    accessorKey: 'email'
  },
  {
    header: ({ column }) => {
      return (
        <SortableHeader
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          order={column.getIsSorted()}
        >
          LHD
        </SortableHeader>
      );
    },
    accessorKey: 'lhd',
    enableHiding: false
  },
  {
    header: 'Discipline',
    accessorKey: 'discipline',
    enableHiding: false
  },
  {
    header: 'Work Setting',
    accessorKey: 'work_setting',
    enableHiding: false
  },
  {
    header: 'Service Name',
    accessorKey: 'service_name',
    enableHiding: true
  },
  {
    header: 'Years of Clinical Experience',
    accessorKey: 'clinical_experience',
    enableHiding: true
  },
  {
    header: 'Years of ED Experience',
    accessorKey: 'ed_experience',
    enableHiding: true
  },
  {
    header: 'Location',
    accessorKey: 'location',
    enableHiding: true
  },
  {
    id: 'eoi-form-link',
    cell: ({ row }) => {
      return (
        <Anchor
          component={Link}
          to={`${Routes.ExpressionOfInterestViewer}/${row.original.user_id}`}
        >
          View Full EOI Form
        </Anchor>
      );
    },
    enableHiding: false
  }
];

const columnVisibility = {
  'email': false,
  'service_name': false,
  'clinical_experience': false,
  'ed_experience': false,
  'location': false
};

export const AdminPreferenceTable = ({
  preferences
}: AdminPreferenceTableProps) => {
  return (
    <DataTable
      id="preference"
      columns={columns}
      data={preferences}
      visibility={columnVisibility}
      sortable
    />
  );
};
