
import React, { useState, useEffect, useCallback, ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { Table, Text, Group, Tooltip, Button, Box, Anchor, Modal, Stack, Switch, Alert } from '@mantine/core';
import { IconInfoCircle, IconDownload, IconColumns, IconLayoutColumns, IconEye } from '@tabler/icons-react';
import { EventDto, EventEnrolmentDto } from 'types/api/event';
import dayjs from 'dayjs';
import { RootState, UserIndex } from 'types/state';
import { addParticipant as addParticipantRoutine, markAttendance as markAttendanceRoutine } from 'store/event-enrolment/routines';
import { AddParticipantForm } from './AddParticipantForm';
import { Routes } from 'enums';
import { Link, useParams } from 'react-router-dom';
import { AttendanceRow } from './AttendanceRow';
import { getEventParticipantsExport as getEventParticipantsExportRoutine } from 'store/data-export/routines';
import { EventStatus } from 'enums/event/event.status.enum';

type Props = {
  event: EventDto;
  users: UserIndex;
  markAttendance: (arg0: { eventId: string; instanceId: string; values: Record<string, boolean> }) => void;
  isLoading: boolean;
  addParticipant: (arg0: { eventId: string; email: string }) => void;
  coordinatorEmail: string;
  allEnrolments: Record<string, EventEnrolmentDto>;
  enrolmentLists: Record<string, string[]>;
  getEventParticipantsExport: (id: string) => void;
};

const AttendanceComponent: React.FC<Props> = ({ event, users, markAttendance, isLoading, addParticipant, coordinatorEmail, allEnrolments, enrolmentLists, getEventParticipantsExport }) => {
  const { id } = useParams();

  const enrolments = enrolmentLists[event.id] ? enrolmentLists[event.id].map(i => allEnrolments[i]) : [];
  const sortedEnrolments = enrolments.filter(
    (enrolment) => 
      enrolment.status === 'APPROVED' ||
      enrolment.status === 'COMPLETE'
    ).sort((one, two) => {
    const userOne = users[one.userId];
    const userTwo = users[two.userId];

    if (userOne && userTwo) {
      if (!userTwo.basic) return -1;
      if (!userOne.basic) return 1;

      const lastNameOne = userOne.basic.lastName.trim();
      const lastNameTwo = userTwo.basic.lastName.trim();

      if (lastNameOne < lastNameTwo) {
        return -1;
      }
      
      if (lastNameOne > lastNameTwo) {
        return 1;
      }
    }

    return 0;
  });

  const [modal, setModal] = useState<boolean>(false);
  const [isTableSettingsVisible, setIsTableSettingsVisible] = useState<boolean>(false);

  const [isEmailVisible, setIsEmailVisible] = useState(false);

  const handleDownloadParticipantsClick = useCallback(() => {
    getEventParticipantsExport(id);
  }, [id]);

  const [editing, setEditing] = useState<Record<string, Record<string, boolean> | false>>(
    event.instances.reduce(
      (acc, instance) => ({
        ...acc,
        [instance.id]: false
      }),
      {}
    )
  );

  useEffect(() => {
    setEditing(
      event.instances.reduce<Record<string, Record<string, boolean> | false>>(
        (acc1, instance) => ({
          ...acc1,
          [instance.id]: enrolments.every(e => typeof e.attendance[instance.id] === 'undefined')
            ? enrolments.reduce<Record<string, boolean>>(
                (acc2, enrolment) =>
                  enrolment.status === 'APPROVED'
                    ? {
                        ...acc2,
                        [enrolment.id]: Boolean(enrolment.attendance[instance.id])
                      }
                    : acc2,
                {}
              )
            : false
        }),
        {}
      )
    );
  }, [enrolmentLists, allEnrolments]);

  const handleShowHideBtnClick = useCallback(() => {
    setIsTableSettingsVisible(true);
  }, [])

  const handleTableSettingsModalClose = useCallback(() => {
    setIsTableSettingsVisible(false);
  }, []);

  const handleEmailSwitchToggle = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setIsEmailVisible(e.currentTarget.checked);
  }, []);

  return (
    <Stack>
      <Stack>
        {
          !event.ioiSignoff && (
            <Alert icon={<IconInfoCircle />} color='yellow'>
              Participant list will become visible once IOI has locked in your participants
            </Alert>
          )
        }
        <Box sx={{ flex: 1 }}>
          <Text fw="bold" size="sm">
            Prefer to send us your paper attendance sheet?
          </Text>
          <Text size="sm">
            Email attendance sheet to <Anchor href="mailto:elearning@insideoutinstitute.org.au">elearning@insideoutinstitute.org.au</Anchor> to mark attendance on your behalf.
          </Text>
        </Box>
        <Group>
          <Button color="teal" leftIcon={<IconDownload size={20} />} uppercase disabled={!enrolments.every(e => e.status !== 'PENDING')} onClick={handleDownloadParticipantsClick}>
              Download Participant List
            </Button>
          {
            (event.status === 'READY' || event.status === 'STARTING_SOON') && (
            <Button color="cyan" uppercase leftIcon={<IconDownload />} component={Link} to={`${Routes.AttendanceSheet}/${event.id}`}>
              Download Attendance Sheet
            </Button>
            )
          }
          <Button color="red" uppercase onClick={() => setModal(true)}>
            Add Participant
          </Button>
        </Group>
      </Stack>

      <Modal opened={modal} onClose={() => setModal(false)} title="Add Participant" centered>
        <AddParticipantForm
          eventId={event.id}
          onSubmit={({ eventId, email }) => {
            addParticipant({ eventId, email });
            setModal(false);
          }}
          coordinatorEmail={coordinatorEmail}
        />
      </Modal>

      <Modal opened={isTableSettingsVisible} onClose={handleTableSettingsModalClose} title="Show  / Hide Table Columns" centered>
        <Switch label="Email" checked={isEmailVisible} onChange={handleEmailSwitchToggle} />
      </Modal>
      
      <Stack spacing='xs'>
        <Group position='right'>
          <Button variant='outline' color='blue' onClick={handleShowHideBtnClick}>
            <IconEye style={{ marginRight: '0.25rem' }} />
            <span>
              Show / Hide
            </span>
          </Button>
        </Group>

        <Table>
          <thead>
            <tr>
              <th>Count</th>
              <th>Participant Name</th>
              { isEmailVisible && <th>Email</th> }
              <th>LHD</th>
              <th>Pre-requisite Completion</th>
              <th>
                <Tooltip label="Pre-survey will unlock 72 hours before training, regardless of participant progress.">
                  <Group spacing="xs">
                    <IconInfoCircle />
                    <Text>Pre-survey Completion</Text>
                  </Group>
                </Tooltip>
              </th>
              {event.instances.map(instance => (
                <th key={instance.id}>{dayjs(instance.startsAt).format('DD MMM')}</th>
              ))}
              <th>Post-survey Completion</th>
            </tr>
          </thead>
          <tbody>
            {sortedEnrolments.map((enrolment, index) => (
              <AttendanceRow
                key={enrolment.id}
                count={index + 1}
                enrolment={enrolment}
                user={users[enrolment.userId]}
                instances={event.instances}
                isLoading={isLoading}
                nBEFORE={event.f2f.BEFORE.length}
                nIMMEDIATELY_BEFORE={event.f2f.IMMEDIATELY_BEFORE.length}
                nAFTER={event.f2f.AFTER.length}
                onChange={(instanceId: string, checked: boolean) => {
                  setEditing({
                    ...editing,
                    [instanceId]: {
                      ...editing[instanceId],
                      [enrolment.id]: checked
                    }
                  });
                }}
                editing={editing}
                showEmail={isEmailVisible}
              />
            ))}
            <tr>
              <td />
              <td />
              <td />
              {isEmailVisible && <td /> }
              <td />
              <td />
              {event.instances.map(instance => (
                <td key={instance.id}>
                  {editing[instance.id] && (
                    <Button
                      uppercase
                      fullWidth
                      color="green"
                      disabled={dayjs() < dayjs(instance.startsAt)}
                      onClick={() => {
                        markAttendance({
                          eventId: event.id,
                          instanceId: instance.id,
                          values: editing[instance.id] || {} // this || is for typescript
                        });
                        setEditing({ ...editing, [instance.id]: false });
                      }}
                      loading={isLoading}
                    >
                      Save
                    </Button>
                  )}
                  {!editing[instance.id] && (
                    <Button
                      uppercase
                      fullWidth
                      color="blue"
                      disabled={dayjs() < dayjs(instance.startsAt)}
                      onClick={() =>
                        setEditing({
                          ...editing,
                          [instance.id]: enrolments.reduce<Record<string, boolean>>(
                            (acc, enrolment) => ({
                              ...acc,
                              [enrolment.id]: Boolean(enrolment.attendance[instance.id])
                            }),
                            {}
                          )
                        })
                      }
                    >
                      Edit
                    </Button>
                  )}
                </td>
              ))}
              <td />
            </tr>
          </tbody>
        </Table>
      </Stack>
    </Stack>
  );
};

const mapStateToProps = (state: RootState) => ({
  users: state.users.LEARNER,
  isLoading: state.event.isLoading || state.eventEnrolment.isLoading,
  coordinatorEmail: state.user.email,
  allEnrolments: state.eventEnrolment.enrolments,
  enrolmentLists: state.eventEnrolment.enrolmentLists
});

const mapDispatchToProps = {
  markAttendance: markAttendanceRoutine,
  addParticipant: addParticipantRoutine,
  getEventParticipantsExport: getEventParticipantsExportRoutine
};

export const Attendance = connect(mapStateToProps, mapDispatchToProps)(AttendanceComponent);
