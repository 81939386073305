import { ColumnDef } from '@tanstack/react-table';
import { DataTable } from 'components/Table';
import React from 'react';
import { EOI } from '../types/eoi';
import { ArchivedEOIAction } from './archived-eoi-action';

interface ArchivedEOIListProps {
  eois: Array<EOI>;
}

const columns: ColumnDef<EOI>[] = [
  {
    header: 'Participant Name',
    accessorFn: row => row.name || row.email,
    enableHiding: false
  },
  {
    header: 'Email',
    accessorKey: 'email'
  },
  {
    header: 'LHD',
    accessorKey: 'lhd',
    enableHiding: false
  },
  {
    header: 'Discipline',
    accessorKey: 'discipline',
    enableHiding: false
  },
  {
    header: 'Work Setting',
    accessorKey: 'work_setting',
    enableHiding: false
  },
  {
    header: 'Service Name',
    accessorKey: 'service_name',
    enableHiding: true
  },
  {
    header: 'Years of Clinical Experience',
    accessorKey: 'clinical_experience',
    enableHiding: true
  },
  {
    header: 'Years of ED Experience',
    accessorKey: 'ed_experience',
    enableHiding: true
  },
  {
    header: 'Location',
    accessorKey: 'location',
    enableHiding: true
  },
  {
    id: 'action',
    cell: ({ row }) => {
      return (
        <ArchivedEOIAction
          enrolmentId={row.original.enrolment_id}
          userId={row.original.user_id}
        />
      );
    },
    enableHiding: false
  }
];

const columnVisibility = {
  'email': false,
  'service_name': false,
  'clinical_experience': false,
  'ed_experience': false,
  'location': false
};

export const ArchivedEOIList = ({ eois }: ArchivedEOIListProps) => {
  return (
    <DataTable
      id="archived"
      columns={columns}
      data={eois}
      getRowId={row => row.enrolment_id}
      visibility={columnVisibility}
      droppable
    />
  );
};
