import { Droppable } from '@hello-pangea/dnd';
import { Button, Group, Modal, Stack, Switch, Table } from '@mantine/core';
import { IconEye } from '@tabler/icons-react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  VisibilityState
} from '@tanstack/react-table';
import React, { Ref, useState } from 'react';
import { DraggableRow } from './draggable-row';
import { Row } from './row';

interface DataTableProps<TData, TValue> {
  id: string;
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  visibility?: VisibilityState;
  getRowId?: (row: TData) => string;
  sortable?: boolean;
  droppable?: boolean;
  draggable?: boolean;
  extraRowData?: Record<string, unknown>;
}

export const DataTable = <TData, TValue>({
  id,
  columns,
  data,
  visibility,
  getRowId,
  sortable,
  droppable,
  draggable
}: DataTableProps<TData, TValue>) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
    visibility ?? {}
  );
  const [visibilityModalOpen, setVisibilityModalOpen] = useState(false);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      sorting,
      columnVisibility
    },
    enableSorting: sortable ?? false
  });

  return (
    <Stack>
      <Modal
        opened={visibilityModalOpen}
        onClose={() => setVisibilityModalOpen(false)}
        title="Show  / Hide Table Columns"
        centered
      >
        <Stack>
          {table
            .getAllColumns()
            .filter(column => column.getCanHide())
            .map(column => {
              return (
                <Switch
                  key={column.id}
                  label={column.columnDef.header.toString()}
                  checked={column.getIsVisible()}
                  onChange={e =>
                    column.toggleVisibility(e.currentTarget.checked)
                  }
                  style={{ textTransform: 'capitalize' }}
                />
              );
            })}
        </Stack>
      </Modal>
      <Group position="right">
        <Button
          variant="outline"
          color="blue"
          onClick={() => setVisibilityModalOpen(true)}
        >
          <IconEye style={{ marginRight: '0.25rem' }} />
          <span>Show / Hide</span>
        </Button>
      </Group>
      <Table>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        {droppable && (
          <Droppable
            droppableId={id}
            renderClone={(provided, _, rubric) => {
              if (!table.getRowModel().rows) return null;

              const row = table.getRowModel().rows[rubric.source.index];

              return (
                <tr
                  ref={provided.innerRef}
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                  {...provided.draggableProps}
                >
                  {row.getVisibleCells().map(cell => (
                    <td
                      key={cell.id}
                      {...(cell.column.id === 'handle' &&
                        provided.dragHandleProps)}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              );
            }}
          >
            {provided => (
              <tbody ref={provided.innerRef} {...provided.droppableProps}>
                {table.getRowModel().rows?.length ? (
                  table
                    .getRowModel()
                    .rows.map(row =>
                      draggable ? (
                        <DraggableRow key={row.id} row={row} />
                      ) : (
                        <Row key={row.id} row={row} />
                      )
                    )
                ) : (
                  <tr>
                    <td>No results.</td>
                  </tr>
                )}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        )}

        {!droppable && (
          <tbody>
            {table.getRowModel().rows?.length ? (
              table
                .getRowModel()
                .rows.map(row => <Row key={row.id} row={row} />)
            ) : (
              <tr>
                <td>No results.</td>
              </tr>
            )}
          </tbody>
        )}
      </Table>
    </Stack>
  );
};
