import { queryOptions, useQuery } from '@tanstack/react-query';
import { api } from 'lib/api-client';
import { QueryConfig } from 'lib/react-query';
import { F2FExtended } from 'types/backend';

export const getF2F = ({
  f2fId
}: {
  f2fId: string;
}): Promise<{ data: F2FExtended }> => {
  return api.get(`/f2f-new/${f2fId}`);
};

export const getF2FQueryOptions = (f2fId: string) => {
  return queryOptions({
    queryKey: ['f2fs', f2fId],
    queryFn: () => getF2F({ f2fId })
  });
};

type UseF2FOptions = {
  f2fId: string;
  queryConfig?: QueryConfig<typeof getF2FQueryOptions>;
};

export const useF2F = ({ f2fId, queryConfig }: UseF2FOptions) => {
  return useQuery({
    ...getF2FQueryOptions(f2fId),
    ...queryConfig
  });
};
