export enum AdminRoutes {
  users = '/administration/users',
  faceToFaceList = '/administration/face-to-face',
  faceToFaceNew = '/administration/face-to-face/new',
  faceToFaceView = '/administration/face-to-face/view',
  courseList = '/administration/course',
  courseNew = '/administration/course/new',
  courseView = '/administration/course/view',
  couponList = '/administration/coupon',
  scormList = '/administration/modules',
  scormNew = '/administration/modules/new',
  scormView = '/administration/modules/view',
  surveyList = '/administration/survey',
  surveyNew = '/administration/survey/new',
  surveyView = '/administration/survey/view',
  eventList = '/administration/event',
  eventNew = '/administration/event/new',
  eventView = '/administration/event/view',
  fileList = '/administration/media-library',
  supervisions = '/administration/supervisions',
  dataExport = '/administration/data-export',
  reports = '/administration/reports',
  courseEnrolmentView = '/administration/course-enrolment'
}
