import { LoadingOverlay, Title } from '@mantine/core';
import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { Coupon } from 'types/backend';
import { DataTable } from 'components/Table';
import { useCoupons } from 'features/coupons/api/get-coupons';

const columns: ColumnDef<Coupon>[] = [
  {
    accessorKey: 'stripe_id',
    header: 'Coupon'
  }
];

export const CouponList = () => {
  const coupons = useCoupons();

  if (coupons.isLoading) {
    return <LoadingOverlay visible />;
  }

  return (
    <div>
      <Title order={2}>Coupons</Title>
      <DataTable id="coupon" columns={columns} data={coupons.data.data} />
    </div>
  );
};
