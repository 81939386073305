/* eslint-disable no-param-reassign */
import Axios, { InternalAxiosRequestConfig } from 'axios';
import { env } from 'config/env';
import { Routes } from 'enums';
import { ErrorCodes } from 'enums/error-codes';
import { authInfoManagerHolder } from 'service/auth-info-manager-holder';
import { Tokens } from 'types/api/auth';

function authRequestInterceptor(config: InternalAxiosRequestConfig) {
  if (config.headers) {
    config.headers.Accept = 'application/json';
  }

  const authManger = authInfoManagerHolder.getAuthInfoManager();
  const { accessToken: token } = authManger.getTokens();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}

export const api = Axios.create({
  baseURL: env.BACKEND_ORIGIN
});

api.interceptors.request.use(authRequestInterceptor);
api.interceptors.response.use(
  response => response.data,
  async error => {
    const code = error.response?.data?.error?.code;

    if (code !== ErrorCodes.UNAUTHORIZED) {
      return Promise.reject(error);
    }

    const authManger = authInfoManagerHolder.getAuthInfoManager();
    const tokens = authManger.getTokens();

    try {
      const { refreshToken } = tokens;
      const response = await Axios.get('/auth/refresh', {
        baseURL: env.BACKEND_ORIGIN,
        headers: {
          'X-Refresh-Token': refreshToken
        }
      });

      if (response.data?.data) {
        const dto: Tokens = response.data.data;
        authManger.setTokens(dto);

        const { config } = error;
        config.headers.Authorization = `Bearer ${dto.accessToken}`;

        return await new Promise((resolve, reject) => {
          Axios.request(config)
            .then(res => {
              resolve(res);
            })
            .catch(e => {
              reject(e);
            });
        });
      }

      if (response.data?.error.code === ErrorCodes.UNAUTHORIZED) {
        authManger.logout();
        window.location.href = `${window.location.origin}${Routes.Login}`;
      }
    } catch (err) {
      return Promise.reject(err);
    }

    return Promise.reject(error);
  }
);
