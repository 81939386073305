import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState, CourseIndex, UserIndex } from 'types/state';
import { fetchUsersByRole as fetchUsersByRoleRoutine } from 'store/users/routines';
import { enrolUserInCourse as enrolUserInCourseRoutine } from 'store/course/routines';
import { AdminRoutes, UserRoles } from 'enums';
import { Select, Button, Grid, Table } from '@mantine/core';
import { useForm } from '@mantine/form';
import dayjs from 'dayjs';

type Props = {
  id: string;
  learners: UserIndex;
  courses: CourseIndex;
  fetchUsersByRole: (role: UserRoles) => void;
  enrolUserInCourse: (arg0: { courseId: string; userId: string }) => void;
};

export const CourseEnrolmentsComponent: React.FC<Props> = ({ id, learners, courses, fetchUsersByRole, enrolUserInCourse }) => {
  const form = useForm({
    initialValues: {
      userId: undefined
    },
    validate: values => ({
      userId: values.userId && learners[values.userId] ? null : 'Invalid user'
    })
  });

  useEffect(() => {
    fetchUsersByRole(UserRoles.LEARNER);
  }, []);

  const navigate = useNavigate();

  const course = courses[id];

  if (!course.enrolments) return null;

  return (
    <>
      <form
        onSubmit={form.onSubmit(values => {
          enrolUserInCourse({ courseId: id, userId: values.userId });
        })}
      >
        <Grid my="lg">
          <Grid.Col span={10}>
            <Select
              data={Object.values(learners).map(user => ({
                value: user.id,
                label: user.basic ? `${user.basic.firstName} ${user.basic.lastName} (${user.email})` : user.email
              }))}
              searchable
              {...form.getInputProps('userId')}
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <Button fullWidth type="submit">
              Enrol User
            </Button>
          </Grid.Col>
        </Grid>
      </form>

      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Completed</th>
            <th>Expired</th>
            <th>Expires At</th>
          </tr>
        </thead>
        <tbody>
          {course.enrolments.map(enrolment => {
            const user = learners[enrolment.userId];

            return (
              <tr key={enrolment.id} onClick={() => navigate(`${AdminRoutes.courseEnrolmentView}/${enrolment.id}`)}>
                <td>
                  {user?.basic?.firstName} {user?.basic?.lastName}
                </td>
                <td>{user?.email}</td>
                <td>{enrolment.completed ? 'Yes' : 'No'}</td>
                <td>{enrolment.isActive ? 'Active' : 'Expired'}</td>
                <td>{dayjs(enrolment.expiresAt).format('DD MMM YYYY')}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  courses: state.course.courses,
  learners: state.users[UserRoles.LEARNER]
});

const mapDispatchToProps = {
  fetchUsersByRole: fetchUsersByRoleRoutine,
  enrolUserInCourse: enrolUserInCourseRoutine
};

export const CourseEnrolments = connect(mapStateToProps, mapDispatchToProps)(CourseEnrolmentsComponent);
