import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchCourse as fetchCourseRoutine } from 'store/course/routines';
import { Title, Text, Tabs, Alert, LoadingOverlay, Group, Box, Image, Anchor } from '@mantine/core';
import { CourseIndex, RootState } from 'types/state';
import { CourseTasks } from './CourseTasks';
import { CourseEnrolments } from './CourseEnrolments';
import { Metadata } from './Metadata';
import { Routes } from 'enums';
import { StorePageMetadata } from './StorePageMetadata';
import { CourseResources } from './CourseResources';
import { CourseEmails } from './CourseEmails';

type Props = {
  courses: CourseIndex;
  isLoading: boolean;
  error?: string;
  fetchCourse: (arg0: string) => void;
};

const CourseViewComponent: React.FC<Props> = ({ courses, isLoading, error, fetchCourse }) => {
  const { id } = useParams();

  useEffect(() => {
    fetchCourse(id);
  }, [id]);

  const course = courses[id];
  if (!course) return null;

  return (
    <>
      <Group>
        <Image src={course.splash} width="250px" height="150px" />

        <Box sx={{ flex: 1 }}>
          <Title order={2}>{course.title}</Title>
          <Text>{course.desc}</Text>
          <Anchor to={`${Routes.CourseStore}/${id}`} component={Link}>
            View Store Page
          </Anchor>
        </Box>
      </Group>

      {error && (
        <Alert title="Error loading face to face type" color="red" my="lg">
          {error}
        </Alert>
      )}

      <Tabs defaultValue="metadata">
        <Tabs.List mb="lg">
          <Tabs.Tab value="metadata">Metadata</Tabs.Tab>
          <Tabs.Tab value="store-page">Store Page Metadata</Tabs.Tab>
          <Tabs.Tab value="scorms">Modules</Tabs.Tab>
          <Tabs.Tab value="resources">Resources</Tabs.Tab>
          <Tabs.Tab value="enrolment">Enrolments</Tabs.Tab>
          <Tabs.Tab value="emails">Emails</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="metadata" my="lg">
          <Metadata course={course} isLoading={isLoading} />
        </Tabs.Panel>
        <Tabs.Panel value="store-page" my="lg">
          <StorePageMetadata course={course} />
        </Tabs.Panel>
        <Tabs.Panel value="scorms" my="lg">
          <CourseTasks id={id} />
        </Tabs.Panel>
        <Tabs.Panel value="resources" my="lg">
          <CourseResources course={course} />
        </Tabs.Panel>
        <Tabs.Panel value="enrolment" my="lg">
          <CourseEnrolments id={id} />
        </Tabs.Panel>
        <Tabs.Panel value="emails" my="lg">
          <CourseEmails course={course} />
        </Tabs.Panel>
      </Tabs>

      <LoadingOverlay visible={isLoading} />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  courses: state.course.courses,
  isLoading: state.course.isLoading,
  error: state.course.error
});

const mapDispatchToProps = {
  fetchCourse: fetchCourseRoutine
};

export const CourseView = connect(mapStateToProps, mapDispatchToProps)(CourseViewComponent);
